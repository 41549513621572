import PropTypes from 'prop-types';

import useIsBrowser from 'hooks/useIsBrowser';

// This component allows us to conditionally render elements only in the
// browser, by checking if the component has mounted. This is useful for
// avoiding and fixing SSR issues, since any elements that are only rendered in
// the browser do not need to match the server-rendered DOM.

// It should not normally be used for elements that affect initial layout,
// since this will cause a flash of incorrect content in the browser.

// It is suitable for:
// - Conditionally rendering elements that are not displayed initially, e.g.
//   modals, popovers
// - Conditionally rendering elements that do not affect overall layout, e.g.
//   close buttons that are absolutely positioned

const BrowserOnly = ({ children }) => {
  const isBrowser = useIsBrowser();

  if (!isBrowser) {
    return null;
  }

  return children;
};

BrowserOnly.propTypes = {
  children: PropTypes.node,
};

export default BrowserOnly;
