import isClient from 'commons/isClient';

const getElementByQuerySelector = selector => {
  if (!isClient()) {
    return null;
  }
  return document.querySelector(selector);
};

export default getElementByQuerySelector;
