import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isTradeUserSidebarOpenAtom from './isTradeUserSidebarOpenAtom';

const useOpenTradeUserSidebar = () => {
  const setIsTradeUserSidebarOpen = useSetAtom(isTradeUserSidebarOpenAtom);

  const openTradeUserSidebar = useCallback(() => {
    setIsTradeUserSidebarOpen(true);
  }, [setIsTradeUserSidebarOpen]);

  return openTradeUserSidebar;
};

export default useOpenTradeUserSidebar;
