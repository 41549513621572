// Where possible, we should use forwarded refs to allow focus directly on
// element refs (e.g. LabeledTextInput) but this is useful for situations where
// we cannot use a ref directly
const focusOnNestedElement = (parentRef, selector) => {
  if (parentRef && parentRef.current) {
    const element = parentRef.current.querySelector(selector);

    if (element) {
      element.focus();
    }
  }
};

export default focusOnNestedElement;
