import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FullWidthContainer = ({
  children,
  'data-xc': dataXc,
  className = '',
  hasPadding = false,
}) => (
  <div
    className={classNames(`m-0 p-0 w-full ${className}`, {
      'pl-5 pr-5 md:pl-[3.333vw] md:pr-[3.333vw] min-[1500px]:pl-[50px] min-[1500px]:pr-[50px]': hasPadding,
    })}
    data-xc={dataXc}
  >
    {children}
  </div>
);

FullWidthContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  'data-xc': PropTypes.string,
  hasPadding: PropTypes.bool,
};

export default FullWidthContainer;
