import { useKeyPressEvent } from 'react-use';

import { isEscKey } from '../helpers/keys';

const useOnEsc = (isEnabled, callback) => {
  useKeyPressEvent(isEscKey, () => {
    if (isEnabled && callback) {
      callback();
    }
  });
};

export default useOnEsc;
