import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useRandomId from 'hooks/useRandomId';
import IconCheck from 'components/icons/iconCheck';
import { colorWhite } from 'styles/layout/colors';

const Checkbox = ({
  checked = false,
  children,
  className = '',
  disabled,
  id: customInputId,
  isHovered,
  name,
  onChange,
  onClick,
  size = 'md',
  value = '',
}) => {
  const randomInputId = useRandomId('Checkbox__input');
  const id = customInputId || randomInputId;

  return (
    <label
      className={classNames(`group flex relative ${className}`, {
        'hover:cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      })}
      id={`${id}-label`}
      htmlFor={id}
    >
      <input
        aria-labelledby={`${id}-label`}
        className={classNames(
          'peer inset-0 h-full opacity-0 absolute w-full z-[1]',
          {
            'group-hover:cursor-pointer': !disabled,
            'cursor-not-allowed': disabled,
          }
        )}
        type="checkbox"
        checked={!!checked}
        disabled={disabled}
        id={id}
        name={name}
        value={value}
        onChange={event => {
          if (disabled) {
            // If Checkbox is disabled, we should not call onChange or onClick
            return;
          }

          if (onChange) {
            onChange(event);
          }

          if (onClick) {
            const isChecked =
              !!event && !!event.target && !!event.target.checked;
            onClick(isChecked);
          }
        }}
        tabIndex={disabled ? '-1' : undefined}
      />

      <span
        className={classNames(
          'items-center rounded border-[1px] flex h-[18px] justify-center left-0 mt-1 absolute top-0 w-[18px]',
          {
            'group-hover:cursor-pointer peer-focus:border-brand peer-focus-visible:outline-2 peer-focus-visible:outline-brand peer-focus-visible:outline-offset-[5px]': !disabled,
            'group-hover:cursor-pointer': isHovered && !disabled,
            'border-[1px] border-brand bg-brand': checked,
            'peer-focus:rounded peer-focus:outline-0': checked && !disabled,
            'bg-gray-light3': disabled,
            'border-gray-light3': !checked,
          }
        )}
        aria-hidden
      >
        <IconCheck
          color={colorWhite}
          className={classNames('h-2 w-[11px]', {
            'opacity-100': checked,
            'opacity-0': disabled || !checked,
          })}
        />
      </span>

      {children && (
        <span
          className={classNames(
            'pl-[30px] [&_a]:text-brand [&_a]:z-[2] [&_a]:focus:underline',
            {
              'group-hover:cursor-pointer text-gray': !disabled,
              'group-hover:cursor-pointer': isHovered && !disabled,
              'text-gray-light2': disabled,
              'text-xs mt-[5px]': size === 'xs',
              'pl-[27px] text-sm mt-px': size === 'sm',
              'text-base font-bold': size === 'md',
            }
          )}
        >
          {children}
        </span>
      )}
    </label>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isHovered: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  value: PropTypes.string,
};

export default Checkbox;
