import React from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import { colorGrayLight7 } from 'styles/layout/colors';
import 'react-placeholder/lib/reactPlaceholder.css';

const Placeholder = ({
  children = '',
  className = '',
  loading = false,
  showLoadingAnimation = true,
  ...props
}) => (
  <ReactPlaceholder
    ready={!loading}
    showLoadingAnimation={showLoadingAnimation}
    className={`Placeholder ${className}`}
    color={colorGrayLight7}
    rows={1}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </ReactPlaceholder>
);

Placeholder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  showLoadingAnimation: PropTypes.bool,
};

export default Placeholder;
