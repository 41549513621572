import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import getElementByQuerySelector from 'commons/getElementByQuerySelector';

const FormikFirstErrorFieldFocusOnSubmit = ({
  formFieldRefs,
  arrayFields = [],
}) => {
  const { errors, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }

    const formFields = Object.keys(errors);
    let finalArrayKeyName = '';
    let callNestedElement = false;
    const firstErrorField = formFields.find(fieldName => {
      if (arrayFields.includes(fieldName)) {
        errors[fieldName].forEach((fieldValue, index) => {
          const fieldKeys = Object.keys(fieldValue || {});
          const keyName = fieldKeys.find(fieldKey => !!fieldValue[fieldKey]);
          if (keyName && !finalArrayKeyName) {
            finalArrayKeyName = `${fieldName}${index}${keyName}`;
            callNestedElement = true;
          }
        });
        return true;
      }
      return !!errors[fieldName];
    });
    const finalFirstErrorField = finalArrayKeyName || firstErrorField;
    if (
      finalFirstErrorField &&
      (formFieldRefs?.[finalFirstErrorField]?.current || callNestedElement)
    ) {
      if (callNestedElement) {
        const el = getElementByQuerySelector(`.${finalFirstErrorField}`);
        if (el) {
          el.focus();
        }
      } else {
        formFieldRefs[finalFirstErrorField].current.focus();
      }
    }
  }, [arrayFields, errors, formFieldRefs, isSubmitting]);

  return null;
};

FormikFirstErrorFieldFocusOnSubmit.propTypes = {
  arrayFields: PropTypes.arrayOf(PropTypes.string),
  formFieldRefs: PropTypes.object,
};

export default FormikFirstErrorFieldFocusOnSubmit;
