import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import getImageUrlWithoutQuery from 'commons/getImageUrlWithoutQuery';
import brokenImageUrlsAtom from './brokenImageUrlsAtom';

const useSetBrokenImageUrl = () => {
  const setBrokenImageUrlAtom = useSetAtom(brokenImageUrlsAtom);

  const setBrokenImageUrl = useCallback(
    brokenImageUrl => {
      if (!brokenImageUrl) {
        return;
      }

      const cleanBrokenImageUrl = getImageUrlWithoutQuery(brokenImageUrl);

      setBrokenImageUrlAtom(prevBrokenImageUrls =>
        prevBrokenImageUrls.includes(brokenImageUrl)
          ? prevBrokenImageUrls
          : [...prevBrokenImageUrls, cleanBrokenImageUrl]
      );
    },
    [setBrokenImageUrlAtom]
  );

  return setBrokenImageUrl;
};

export default useSetBrokenImageUrl;
