import getPath from './getPath';
import getOrigin from './getOrigin';

const getUrlWithoutQuery = (urlString = '') => {
  const origin = getOrigin(urlString);
  const path = getPath(urlString);

  return `${origin}${path}`;
};

export default getUrlWithoutQuery;
