import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';
import Passthrough from 'components/consumer/Passthrough';

const MobileAndTabletOnlyLegacy = loadable(() =>
  import('./MobileAndTabletOnlyLegacy')
);

// This component conditionally only renders children on mobile and tablet in
// the legacy consumer app, but in the new app will render at all breakpoints
// as required by React 18.
const MobileAndTabletOnly = isNewConsumerApp
  ? Passthrough
  : MobileAndTabletOnlyLegacy;

export default MobileAndTabletOnly;
