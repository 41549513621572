import isServer from './isServer';

const getOrCreateElementById = id => {
  if (!isServer()) {
    const element = document.getElementById(id);

    if (element) {
      return element;
    }

    if (!element) {
      const modalRoot = document.createElement('div');
      modalRoot.id = id;
      document.body.appendChild(modalRoot);
      return modalRoot;
    }
  }

  return null;
};

export default getOrCreateElementById;
