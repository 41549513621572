import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconClose from 'components/icons/iconClose';

const ClearAllFiltersButton = ({
  children = 'Clear all filters',
  className = '',
  onClick,
  variant,
}) => (
  <button
    className={classNames(
      'text-sm font-bold items-center border-0 rounded-[30px] text-[#d86262] cursor-pointer flex justify-center leading-[.5] mx-0 my-1 px-4 py-[.6rem] bg-red-light',
      className,
      {
        '!m-0': variant === 'ShopByMobile',
      }
    )}
    type="button"
    onClick={onClick}
  >
    <IconClose className="mr-2.5 w-2.5 [&_path]:fill-[#d86262]" />
    <span>{children}</span>
  </button>
);

ClearAllFiltersButton.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['ShopByMobile']),
};

export default ClearAllFiltersButton;
