import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconClose from 'components/icons/iconClose';
import IconSimpleArrowLeft from 'components/icons/iconSimpleArrowLeft';

const MobileModalCloseIcon = ({
  color = 'content',
  icon = 'close',
  variant,
}) => {
  if (icon === 'arrow') {
    return (
      <IconSimpleArrowLeft
        className={classNames(
          '[&_path]:transition-all [&_path]:duration-200 [&_path]:ease-in-out',
          {
            '[&_path]:stroke-[#424242]': color === 'content',
            '[&_path]:stroke-[#fff]': color === 'white',
          }
        )}
        height="18"
        width="14"
      />
    );
  }

  return (
    <IconClose
      className={classNames(
        '[&_path]:transition-all [&_path]:duration-200 [&_path]:ease-in-out',
        {
          '[&_path]:fill-gray':
            color === 'content' && variant !== 'ModularAddProducts',
          '[&_path]:fill-white':
            color === 'white' || variant === 'ModularAddProducts',
        }
      )}
      height="14"
      width="14"
    />
  );
};

MobileModalCloseIcon.propTypes = {
  icon: PropTypes.oneOf(['close', 'close-white', 'arrow']),
  color: PropTypes.oneOf(['content', 'white']),
  variant: PropTypes.oneOf(['ModularAddProducts']),
};

export default MobileModalCloseIcon;
