import focusOnNestedElement from 'commons/focusOnNestedElement';

// Note: this only supports our most common standard form fields, but this
// now covers the majority of our forms
const focusOnFirstFieldWithError = parentRef =>
  focusOnNestedElement(
    parentRef,
    '[data-xc="LabeledFieldWrapper"][data-has-error="true"] input, [data-xc="LabeledFieldWrapper"][data-has-error="true"] select, [data-xc="LabeledFieldWrapper"][data-has-error="true"] textarea'
  );

export default focusOnFirstFieldWithError;
