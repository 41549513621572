import React from 'react';
import PropTypes from 'prop-types';

const SimpleArrowLeft = ({ stroke, ...props }) => (
  <svg width={15} height={24} viewBox="0 0 15 24" {...props}>
    <path
      d="M13.579 23L2 11.155 13.579 1"
      stroke={stroke || '#585858'}
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

SimpleArrowLeft.propTypes = {
  stroke: PropTypes.string,
};

export default SimpleArrowLeft;
