import { useEffect, useRef } from 'react';
import { useKey } from 'react-use';

import getFirstFocusableElement from '../helpers/getFirstFocusableElement';
import getLastFocusableElement from '../helpers/getLastFocusableElement';
import { isTabAndNotShiftKey, isTabAndShiftKey } from '../helpers/keys';
import isActiveElement from '../helpers/isActiveElement';

const useTrapFocus = (isEnabled, parentRef) => {
  const isEnabledRef = useRef(false);
  const parentElementRef = useRef(false);
  const currentParentRef = parentRef.current;

  useEffect(() => {
    isEnabledRef.current = isEnabled;
  }, [isEnabled]);

  useEffect(() => {
    parentElementRef.current = currentParentRef;
  }, [currentParentRef]);

  // If user presses tab while on the last focusable element, focus() on the
  // first focusable element
  useKey(
    isTabAndNotShiftKey,
    event => {
      if (isEnabledRef.current && parentElementRef.current) {
        const firstFocusableElement = getFirstFocusableElement(
          parentElementRef.current
        );
        const lastFocusableElement = getLastFocusableElement(
          parentElementRef.current
        );

        if (firstFocusableElement && isActiveElement(lastFocusableElement)) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    },
    { event: 'keydown' }
  );

  // If user presses shift-and-tab while on the first focusable element,
  // focus() on the last focusable element
  useKey(
    isTabAndShiftKey,
    event => {
      if (isEnabledRef.current && parentElementRef.current) {
        const firstFocusableElement = getFirstFocusableElement(
          parentElementRef.current
        );
        const lastFocusableElement = getLastFocusableElement(
          parentElementRef.current
        );

        if (lastFocusableElement && isActiveElement(firstFocusableElement)) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      }
    },
    { event: 'keydown' }
  );
};

export default useTrapFocus;
