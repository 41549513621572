import PropTypes from 'prop-types';

// Within components that conditionally wrap children in either the new or old
// versions of the consumer app (e.g. conditionally showing an element based on
// current breakpoint in the old app, but always showing it in the new app), we
// can conditionally use this component to always render the children.
const Passthrough = ({ children }) => children;

Passthrough.propTypes = {
  children: PropTypes.node,
};

export default Passthrough;
