import { useKeyPressEvent } from 'react-use';

import isActiveElementRef from '../helpers/isActiveElementRef';
import { isEnterKey, isSpacebarKey } from '../helpers/keys';

// Note: a ref for the element that we are adding keyboard control to must be
// passed in, so that the callback is only called when the element is focus
const useOnEnterOrSpacebar = (isEnabled, ref, callback) => {
  useKeyPressEvent(isEnterKey, () => {
    if (isEnabled && isActiveElementRef(ref) && callback) {
      callback();
    }
  });

  useKeyPressEvent(isSpacebarKey, () => {
    if (isEnabled && isActiveElementRef(ref) && callback) {
      callback();
    }
  });
};

export default useOnEnterOrSpacebar;
