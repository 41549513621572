import React from 'react';
import PropTypes from 'prop-types';

import defaultProductCardFallbackIcon from 'assets/icons/image-coming-soon.svg';

const DefaultFallbackImage = ({ brokenImageSrc, className }) => (
  <img
    src={defaultProductCardFallbackIcon}
    width="189"
    height="189"
    alt=""
    className={className || ''}
    data-broken-image-src={brokenImageSrc}
  />
);

DefaultFallbackImage.propTypes = {
  className: PropTypes.string,
  brokenImageSrc: PropTypes.string,
};

export default DefaultFallbackImage;
