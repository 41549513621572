import { useThrottle, useWindowScroll } from 'react-use';

const useThrottledWindowScrollPosition = ({ callback, time = 100 }) => {
  const { x = 0, y = 0 } = useWindowScroll();

  useThrottle(
    () => {
      callback({ x, y });
    },
    time,
    [x, y]
  );
};

export default useThrottledWindowScrollPosition;
