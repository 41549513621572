import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconSidebarClose from 'components/icons/IconSidebarClose';
import notificationBannerHelper from 'components/consumer/NotificationBanner/utils';
import useScreen from 'hooks/useScreen';
import FBSignup from '../fb-signup';
import PreLogin from '../pre-login';
import LoginForm from '../login-form';
import CreatePassword from '../create-password';

const LoginContainer = ({
  initialState = {
    email: '',
    showLogin: false,
    showSignUp: false,
    showFBSignUp: false,
    showResetPassword: false,
    showCreatePassword: false,
  },
  isLoginPage = false,
  noPadding = false,
  onClose,
  onGuestLoginClick = () => undefined,
  onLogin,
  showClose = false,
  showGuestLogin = false,
  showNotificationOnLogin = true,
  staticContext,
  variant,
  wishListProduct = null,
}) => {
  const { isMobile } = useScreen();

  const [state, setState] = useState(initialState);

  const actions = {
    onClose,
    onLogin: (user, wishListProductName) => {
      const hasWishListProduct = !!(wishListProduct || wishListProductName);
      if (
        showNotificationOnLogin &&
        (!user.is_designer || hasWishListProduct)
      ) {
        // for wish list wait for 500ms for sidebar to close
        setTimeout(
          () => {
            notificationBannerHelper.showMsg('login', {
              firstName: user.first_name,
              wishListProduct: wishListProduct || wishListProductName,
            });
          },
          hasWishListProduct ? 500 : 0
        );
      }
      if (typeof onLogin === 'function') {
        onLogin(user);
      }
    },
    onSignUp: user => {
      notificationBannerHelper.showMsg('signup', {
        firstName: user.first_name,
        wishListProduct,
      });
      if (!isMobile && onClose) {
        setTimeout(() => {
          onClose();
        }, 100);
      }
      if (isMobile) {
        if (typeof onLogin === 'function') {
          onLogin(user);
        }
      }
    },
    onForgotPasswordClick: email => {
      notificationBannerHelper.showMsg('forgotPassword', {
        email,
      });
    },
    onForgotPasswordSuccess: () => {
      notificationBannerHelper.showMsg('forgotPassword', {
        isSuccess: true,
      });
    },
    showResetPassword: () => {
      setState({
        ...state,
        showLogin: true,
        showSignUp: false,
        showFBSignUp: false,
        showResetPassword: true,
        showCreatePassword: false,
      });
    },
    onLoginClick: () => {
      setState({
        ...state,
        showLogin: true,
        showSignUp: false,
        showFBSignUp: false,
      });
    },
    onEmailChange: evt => {
      setState({ ...state, email: evt.target.value });
    },
    onPasswordChange: evt => {
      setState({ ...state, password: evt.target.value });
    },
    onFirstNameChange: evt => {
      setState({ ...state, first_name: evt.target.value });
    },
    onLastNameChange: evt => {
      setState({ ...state, last_name: evt.target.value });
    },
    onFBCallback: fbLoginData => {
      if (fbLoginData && fbLoginData.name && fbLoginData.email) {
        let [first_name, last_name] = fbLoginData.name.split(' ');

        first_name = first_name || fbLoginData.name;
        last_name = last_name || fbLoginData.name;
        setState({
          ...state,
          email: fbLoginData.email,
          first_name,
          last_name,
          fbLoginData,
          showLogin: false,
          showSignUp: false,
          showFBSignUp: true,
          showResetPassword: false,
          showCreatePassword: false,
        });
      }
    },
    onCustomerExistsUpdate: exists => {
      if (exists) {
        setState({
          ...state,
          showLogin: true,
          showSignUp: false,
          showFBSignUp: false,
          showResetPassword: false,
          showCreatePassword: false,
        });
      } else {
        setState({
          ...state,
          showLogin: false,
          showSignUp: true,
          showFBSignUp: false,
          showResetPassword: false,
          showCreatePassword: false,
        });
      }
    },
  };

  return (
    <div
      className={classNames('md:px-[1.625rem] md:py-0 md:w-[27.625rem]', {
        'md:!p-0 md:!w-auto': noPadding,
      })}
    >
      {showClose && !isMobile && (
        <div className="relative max-[767px]:p-0">
          <IconSidebarClose
            style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            onClick={onClose}
          />
        </div>
      )}
      <div
        className={classNames('px-0 pb-0', {
          'pt-[5.125rem] max-[767px]:[border-top:1px_solid_#f1f1f1] max-[767px]:min-h-[calc(100vh_-_60px)] max-[767px]:px-5 max-[767px]:py-9': !noPadding,
          'pt-4 max-[767px]:[border-top:0] max-[767px]:min-h-[auto] max-[767px]:!p-0': noPadding,
          'md:!px-0 md:!py-12':
            variant === 'login-page' || variant === 'reset-password',
        })}
      >
        {!state.showLogin &&
          !state.showSignUp &&
          !state.showFBSignUp &&
          !state.showResetPassword &&
          !state.showCreatePassword && (
            <PreLogin
              state={state}
              actions={actions}
              isLoginPage={isLoginPage}
              staticContext={staticContext}
              showGuestLogin={showGuestLogin}
              onGuestLoginClick={onGuestLoginClick}
              isMobile={isMobile}
            />
          )}
        {state.showLogin && <LoginForm state={state} actions={actions} />}
        {state.showSignUp && (
          <CreatePassword mode="signup" state={state} actions={actions} />
        )}
        {state.showCreatePassword && (
          <CreatePassword
            mode="forgot-password"
            state={state}
            actions={actions}
          />
        )}
        {state.showFBSignUp && (
          <FBSignup
            state={state}
            actions={actions}
            staticContext={staticContext}
          />
        )}
      </div>
    </div>
  );
};

LoginContainer.propTypes = {
  initialState: PropTypes.shape({
    email: PropTypes.string,
    showLogin: PropTypes.bool,
    showSignUp: PropTypes.bool,
    showFBSignUp: PropTypes.bool,
    showResetPassword: PropTypes.bool,
    showCreatePassword: PropTypes.bool,
  }),
  isLoginPage: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  onGuestLoginClick: PropTypes.func,
  onLogin: PropTypes.func,
  showClose: PropTypes.bool,
  showGuestLogin: PropTypes.bool,
  showNotificationOnLogin: PropTypes.bool,
  staticContext: PropTypes.shape({}),
  variant: PropTypes.oneOf(['login-page', 'reset-password']),
  wishListProduct: PropTypes.string,
};

export default LoginContainer;
