export const DEFAULT_IMGIX_PARAMS = {
  fit: 'crop',
  crop: 'entropy',
  auto: 'format,compress',
  quality: 30,
  cs: 'srgb',
};

export const TRANSPARENT_FILLER_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
