import React from 'react';
import PropTypes from 'prop-types';

const IconHeart2 = ({
  className = '',
  color = '#585858',
  fill = 'none',
  height = 19,
  width = 21,
}) => (
  <svg width={width} height={height} viewBox="0 0 21 19" className={className}>
    <path
      d="M14.433 1.448a5.068 5.068 0 00-3.717 2.241 5.068 5.068 0 10-7.808 6.385l7.806 7.808 7.807-7.808a5.068 5.068 0 00-4.088-8.626z"
      stroke={color}
      strokeWidth={1.5}
      fill={fill}
    />
  </svg>
);

IconHeart2.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default IconHeart2;
