import React, { useRef, useState } from 'react';
import md5 from 'md5';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import LabeledTextInput from 'components/consumer/LabeledTextInput';
import { getTempUserIdCookie } from 'commons/localStorage';
import { logError } from 'commons/logger';
import CtaButton from 'components/essentials/Cta/CtaButton';
import focusOnFirstFieldWithError from 'commons/focusOnFirstFieldWithError';
import { EMAIL_REGEX } from 'commons/constants';
import { getRecentlyViewed } from 'components/consumer/cart/utils';
import {
  LOGIN,
  FORGOT_PASSWORD,
  ADD_TO_RECENTLY_VIEWED,
} from 'mocks/mutations';
import avo from 'analytics/avo';
import { sendAnalyticsIdentifyEvent } from 'commons/analytics';
import useOpenTradeUserSidebar from 'global-state/isTradeUserSidebarOpen/useOpenTradeUserSidebar';
import useSetAuthTokenAndUser from 'data-hooks/useUserData/useSetAuthTokenAndUser';

const LoginForm = ({ state, actions }) => {
  const formRef = useRef();
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
  const [, setForgotPasswordError] = useState(null);

  const [addToRecentlyViewed] = useMutation(ADD_TO_RECENTLY_VIEWED);
  const setAuthTokenAndUser = useSetAuthTokenAndUser();

  const openTradeUserSidebar = useOpenTradeUserSidebar();

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: async data => {
      if (!data.login || !data.login.user) {
        if (data.login.errors && data.login.errors[0]?.field === 'email') {
          setIsInvalidEmail(true);
        } else {
          setIsInvalidEmail(false);
        }

        if (
          data.login.errors &&
          (data.login.errors[0]?.field === 'password' ||
            data.login.errors[0]?.message?.toLowerCase()?.includes('password'))
        ) {
          setIsInvalidPassword(true);
        } else {
          setIsInvalidPassword(false);
        }

        focusOnFirstFieldWithError(formRef);
        return null;
      }

      setIsInvalidEmail(false);
      setIsInvalidPassword(false);

      const { access_token, refresh_token, ...user } = data.login.user;

      await setAuthTokenAndUser({
        access_token,
        email: user.email,
        first_name: user.first_name,
        group_id: user.group_id,
        id: user.id,
        is_designer: user.is_designer,
        is_impersonate: user.is_impersonate,
        last_name: user.last_name,
        refresh_token,
        user_discount: user.user_discount,
      });

      actions.onLogin(user);
      if (actions.onClose) {
        actions.onClose();
      }

      addToRecentlyViewed({
        variables: {
          productIds: getRecentlyViewed(),
        },
      });

      if (user.is_designer) {
        openTradeUserSidebar();
      }

      const analyticsUser = {
        customerId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        hashedEmail: user.email ? md5(user.email) : '',
      };
      sendAnalyticsIdentifyEvent(analyticsUser.customerId.toString(), {
        first_name: analyticsUser.firstName,
        last_name: analyticsUser.lastName,
        email: analyticsUser.email,
      });

      avo.loggedIn({
        userId_: analyticsUser.customerId.toString(),
        firstName: analyticsUser.firstName,
        lastName: analyticsUser.lastName,
        hashedEmail: analyticsUser.hashedEmail,
      });
      return null;
    },
  });

  const [forgotPassword, { loading: forgotPasswordLoading }] = useMutation(
    FORGOT_PASSWORD,
    {
      onCompleted: data => {
        if (!data.forgotPassword.success) {
          const rawMessage = data.forgotPassword.message;
          let message = rawMessage;
          try {
            const parsedMsg = JSON.parse(rawMessage);
            message = parsedMsg[0].message;
          } catch (e) {
            logError(e);
            message = e.message;
          }
          setForgotPasswordError(message);
        } else if (actions.onClose) {
          actions.onClose();
        }
      },
    }
  );

  const trimmedEmail = state.email?.trim() ?? '';

  const resetPassword = async () => {
    if (!state.email) {
      setIsInvalidPassword(true);
      return;
    }
    await forgotPassword({
      variables: {
        email: trimmedEmail,
      },
    });
    actions.onForgotPasswordClick(trimmedEmail);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    const tempUserId = getTempUserIdCookie();
    if (!state.password || state.password === '') {
      setIsPasswordEmpty(true);
      return false;
    }
    setIsPasswordEmpty(false);

    setIsInvalidEmail(false);
    setIsInvalidPassword(false);
    login({
      variables: {
        email: trimmedEmail,
        password: state.password,
        rememberMe: state.rememberMe,
        tempUserId,
      },
    });
    return false;
  };

  return (
    <div className="text-gray">
      <div className="consumer-login-form__content">
        <div className="text-xl font-bold text-gray">
          {state.showResetPassword ? 'Forgot Password' : 'Welcome back!'}
        </div>
        <form method="POST" onSubmit={onSubmit} ref={formRef}>
          <LabeledTextInput
            name="email"
            value={trimmedEmail}
            label="Email address"
            autoComplete="username"
            onChange={evt => {
              setIsInvalidEmail(false);
              actions.onEmailChange(evt);
            }}
            onBlur={evt => {
              const isEmailValid = EMAIL_REGEX.test(
                String(evt.target.value).toLowerCase()
              );
              setIsInvalidEmail(!isEmailValid);
            }}
            errorMessage={isInvalidEmail ? 'Email address is invalid' : null}
          />

          {!state.showResetPassword && (
            <>
              <LabeledTextInput
                autoFocus
                name="password"
                type="password"
                label="Password"
                value={state.password}
                autoComplete="current-password"
                onChange={actions.onPasswordChange}
                errorMessage={
                  isPasswordEmpty
                    ? 'Password required'
                    : isInvalidPassword
                    ? 'Incorrect password'
                    : null
                }
              />

              <CtaButton
                type="submit"
                color="black"
                styling="solid-button"
                data-xc="signin-button"
                disabled={loading}
                block
                isLoading={loading}
              >
                Continue
              </CtaButton>
            </>
          )}
        </form>

        <div className="mt-4 text-center">
          <CtaButton
            size="sm"
            hasArrow={false}
            onClick={resetPassword}
            disabled={forgotPasswordLoading}
            block={!!state.showResetPassword}
            color={state.showResetPassword ? 'black' : 'brand'}
            styling={state.showResetPassword ? 'solid-button' : 'link'}
            isLoading={forgotPasswordLoading}
          >
            Reset Password
          </CtaButton>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    rememberMe: PropTypes.bool,
    showResetPassword: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    onEmailChange: PropTypes.func,
    onPasswordChange: PropTypes.func,
    onRememberMeChange: PropTypes.func,
    onForgotPasswordClick: PropTypes.func,
    onClose: PropTypes.func,
    onLogin: PropTypes.func,
  }),
};

export default LoginForm;
