import React from 'react';
import { TextRow } from 'react-placeholder/lib/placeholders';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextPlaceholder = ({ children, className, color = 'inherit', style }) => (
  <TextRow
    className={classNames(
      'animate-placeholder-shimmer [background-color:#f6f7f8] [background:linear-gradient(90deg,#eee_8%,#ddd_18%,#eee_33%)] ![background-size:800px_104px] relative',
      className
    )}
    color={color}
    style={style}
  >
    {children}
  </TextRow>
);

TextPlaceholder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default TextPlaceholder;
