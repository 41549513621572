import React from 'react';
import PropTypes from 'prop-types';
import IcoClose from 'components/icons/iconClose';

const IconClose = ({ svgProps, ...props }) => (
  <div
    id="flex items-center justify-center bg-transparent border border-solid [border-color:#dedede] cursor-pointer h-[45px] w-[45px] rounded-full hover:border-brand [&_svg]:w-3.5 [&_svg]:h-3.5 [&_svg]:!m-0 [&_svg_path]:fill-gray"
    {...props}
  >
    <IcoClose {...svgProps} />
  </div>
);

IconClose.propTypes = {
  svgProps: PropTypes.objectOf(PropTypes.object),
};

export default IconClose;
