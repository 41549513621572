import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';
import Passthrough from 'components/consumer/Passthrough';

const MobileOnlyLegacy = loadable(() => import('./MobileOnlyLegacy'));

// This component conditionally only renders children on mobile in the legacy
// consumer app, but in the new app will render at all breakpoints as required
// by React 18.
const MobileOnly = isNewConsumerApp ? Passthrough : MobileOnlyLegacy;

export default MobileOnly;
