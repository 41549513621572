import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import avo from 'analytics/avo';
import CtaLink from 'components/essentials/Cta/CtaLink';
import CtaButton from 'components/essentials/Cta/CtaButton';
import { getRecentlyViewed } from 'components/consumer/cart/utils';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import { FB_LOGIN_FAVORITES, FB_LOGIN_PREV_PATH } from 'commons/constants';
import {
  getTempUserIdCookie,
  getItemFromStorage,
  removeItemInStorage,
  getTalkativeCustomerId,
} from 'commons/localStorage';
import {
  FACEBOOK_LOGIN,
  ADD_TO_RECENTLY_VIEWED,
  FAVORITE_PRODUCT,
} from 'mocks/mutations';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useSetAuthTokenAndUser from 'data-hooks/useUserData/useSetAuthTokenAndUser';
import PreLogin from '../pre-login';
import { GET_CUSTOMER_IS_EXISTS } from '../queries';

const FBSignUp = ({ state, actions, history, staticContext }) => {
  const [errors, setErrors] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isFBSignup, setIsFBSignup] = useState(false);
  const currentPage = useUserCurrentPageType();

  const [getCustomerIsExists, { data: customerIsExists }] = useLazyQuery(
    GET_CUSTOMER_IS_EXISTS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [addToFavorites] = useMutation(FAVORITE_PRODUCT);
  const [addToRecentlyViewed] = useMutation(ADD_TO_RECENTLY_VIEWED);
  const setAuthTokenAndUser = useSetAuthTokenAndUser();

  const [fbSignUp, { loading }] = useMutation(FACEBOOK_LOGIN, {
    onCompleted: async data => {
      const { continueWithFacebook } = data;
      if (continueWithFacebook.errors) setErrors(!!continueWithFacebook.errors);
      if (!continueWithFacebook.user) {
        setErrors(true);
        return;
      }
      const {
        access_token,
        refresh_token,
        ...user
      } = continueWithFacebook.user;
      if (isFBSignup) {
        avo.emailAcquired({
          userId_: user.id.toString(),
          email: user.email,
          location: currentPage,
          optedInMarketing: true,
          backendEmail: user.email,
          component: avo.Component.ACCOUNT_CREATION,
          talkativeCustomerId: getTalkativeCustomerId(),
        });
        setIsFBSignup(false);
      }
      await setAuthTokenAndUser({
        access_token,
        email: user.email,
        first_name: user.first_name,
        group_id: user.group_id,
        id: user.id,
        is_designer: user.is_designer,
        is_impersonate: false,
        last_name: user.last_name,
        refresh_token,
        user_discount: user.user_discount,
      });

      const fav = getItemFromStorage(FB_LOGIN_FAVORITES);
      actions.onLogin(user, fav && fav.productName);
      const prevPath = getItemFromStorage(FB_LOGIN_PREV_PATH);
      if (typeof actions.onClose === 'function') {
        actions.onClose();
      }
      history.push(
        prevPath && (prevPath === '/' || prevPath.startsWith('/checkout'))
          ? prevPath
          : '/customer/dashboard'
      );
      removeItemInStorage(FB_LOGIN_PREV_PATH);

      addToRecentlyViewed({
        variables: {
          productIds: getRecentlyViewed(),
        },
      });

      if (fav && fav.productID) {
        await addToFavorites({
          variables: {
            productID: fav.productID,
            options: fav.options,
            keepFavorited: true,
          },
          refetchQueries: ['getFavoritedProducts'],
        });
        removeItemInStorage(FB_LOGIN_FAVORITES);
      }
    },
  });

  const doFbSignUp = async () => {
    const tempUID = getTempUserIdCookie();
    await fbSignUp({
      variables: {
        fbLoginData: {
          ...state.fbLoginData,
          email: state.email,
          name: `${state.first_name} ${state.last_name}`,
        },
        tempUserId: tempUID,
      },
    });
  };

  const onSubmit = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsFBSignup(true);
    doFbSignUp();
    return false;
  };

  useEffect(() => {
    if (state.email) {
      getCustomerIsExists({
        variables: {
          email: state.email,
        },
      });
    }
  }, [getCustomerIsExists, state.email]);

  useEffect(() => {
    if (
      customerIsExists &&
      customerIsExists.getCustomerIsExists &&
      !customerIsExists.getCustomerIsExists.errors
    ) {
      if (customerIsExists.getCustomerIsExists.exists) {
        doFbSignUp();
      } else {
        setShowForm(true);
      }
    }
    // using this rule because doFbSignUp is used in this useEffect hook as well as outside of it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerIsExists,
    fbSignUp,
    state.email,
    state.fbLoginData,
    state.first_name,
    state.last_name,
  ]);

  return !showForm ? (
    <PreLogin
      state={{ ...state, email: '' }}
      actions={actions}
      isFbLoading
      staticContext={staticContext}
      errors={errors}
    />
  ) : (
    <div className="text-gray">
      <div className="consumer-fb-signup__content">
        <div className="text-xl font-bold">Complete sign up</div>
        {errors && (
          <div className="text-xl px-12 py-[1.1rem] bg-brand-lightest text-brand-dark font-normal text-center">
            Sorry, We are unable to process your sign-up request.
          </div>
        )}
        <form method="POST" onSubmit={onSubmit}>
          <LabeledTextInput
            autoFocus
            type="text"
            name="first_name"
            label="First Name"
            value={state.first_name}
            onChange={actions.onFirstNameChange}
          />

          <LabeledTextInput
            name="last_name"
            type="text"
            label="Last Name"
            value={state.last_name}
            onChange={actions.onLastNameChange}
          />

          <LabeledTextInput
            disabled
            name="email"
            value={state.email}
            label="Email address"
            autoComplete="username"
            onChange={actions.onEmailChange}
            errorMessage={
              state.isEmailValid ? 'Email address is invalid' : null
            }
          />

          <CtaButton
            type="submit"
            color="black"
            styling="solid-button"
            data-xc="signup-button"
            disabled={loading}
            block
            isLoading={loading}
          >
            Continue
          </CtaButton>
        </form>

        <div className="text-xs text-center mt-4 mx-0 mb-6 [&_a]:px-1 [&_a]:py-0">
          By creating an account, you are agreeing to our
          <CtaLink
            to="/privacy-policy/"
            color="brand"
            hasArrow={false}
            size="xs"
          >
            Privacy Policy
          </CtaLink>
          You also agree to receive exclusive sales and design inspiration via
          email; you can unsubscribe at any time.
        </div>

        <div className="text-sm font-bold text-center">
          Already have an account? &nbsp;
          <CtaButton color="brand" styling="link" hasArrow={false} size="sm">
            Log in
          </CtaButton>
        </div>
      </div>
    </div>
  );
};

FBSignUp.propTypes = {
  state: PropTypes.func,
  actions: PropTypes.shape({
    onLogin: PropTypes.func,
    onClose: PropTypes.func,
    onFirstNameChange: PropTypes.func,
    onLastNameChange: PropTypes.func,
    onEmailChange: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  staticContext: PropTypes.string,
};

export default withRouter(FBSignUp);
