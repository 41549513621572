import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import useRandomId from 'hooks/useRandomId';
import CtaLink from 'components/essentials/Cta/CtaLink';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import {
  getTempUserIdCookie,
  getTalkativeCustomerId,
} from 'commons/localStorage';
import CtaButton from 'components/essentials/Cta/CtaButton';
import { SIGNUP, RESET_PASSWORD } from 'mocks/mutations';
import IconClose from 'components/icons/iconClose';
import { sendAnalyticsIdentifyEvent } from 'commons/analytics';
import avo from 'analytics/avo';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import { INVALID_NAME_CHARACTERS_REGEX, EMAIL_REGEX } from 'commons/constants';
import useSetAuthTokenAndUser from 'data-hooks/useUserData/useSetAuthTokenAndUser';

const initCaps = str => str && str[0].toUpperCase() + str.slice(1);

const IconTick = props => (
  <svg width={11} height={9} viewBox="0 0 11 9" {...props}>
    <g stroke="none" strokeWidth="1.25" fill="none" fillRule="evenodd">
      <g className="tick-stroke" transform="translate(0.000000, 1.000000)">
        <polyline points="0 3.6732398 3.4439402 7 11 0" />
      </g>
    </g>
  </svg>
);

const CreatePassword = ({
  mode = 'signup',
  state,
  actions,
  match,
  history,
}) => {
  const [formError, setFormError] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [firstNameValidations, setFirstNameValidations] = useState({});
  const [lastNameValidations, setLastNameValidations] = useState({});
  const [nameErrorMsgs, setNameErrorMsgs] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({});
  const passwordInfoId = useRandomId('CreatePassword__info');
  const currentPage = useUserCurrentPageType();
  const onPasswordChange = evt => {
    actions.onPasswordChange(evt);

    const { value } = evt.target;
    if (value) {
      setPasswordValidations({
        charLen: value.length >= 6,
        hasLower: /[a-z]/.test(value),
        hasUpper: /[A-Z]/.test(value),
        hasNumber: /[0-9]/.test(value),
      });
    } else {
      setPasswordValidations({});
    }
  };

  const onFirstNameChange = evt => {
    actions.onFirstNameChange(evt);

    const value = evt.target.value?.length > 0 ? evt.target.value.trim() : '';
    setFirstNameValidations({
      isTouched: true,
      isRequired: !value,
      minLength: value.length < 2,
      hasInvalidCharacters: INVALID_NAME_CHARACTERS_REGEX.test(value),
    });
  };

  const onLastNameChange = evt => {
    actions.onLastNameChange(evt);
    const value = evt.target.value?.length > 0 ? evt.target.value.trim() : '';
    setLastNameValidations({
      isTouched: true,
      isRequired: !value,
      minLength: value.length < 2,
      hasInvalidCharacters: INVALID_NAME_CHARACTERS_REGEX.test(value),
    });
  };

  const checkEmailInput = evt => {
    if (!EMAIL_REGEX.test(evt.target.value)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const setAuthTokenAndUser = useSetAuthTokenAndUser();

  const [signUp, { loading: signUpLoading }] = useMutation(SIGNUP, {
    onCompleted: async data => {
      const { signup } = data;
      if (!signup || signup.errors) {
        setFormError(
          get(signup, 'errors.0.message', 'Unable to sign up please try again')
        );
      }

      if (signup.user) {
        actions.onSignUp(signup.user);

        const {
          access_token,
          refresh_token,
          email,
          id,
          last_name,
          first_name,
          group_id,
          is_designer,
          user_discount,
        } = signup.user;

        await setAuthTokenAndUser({
          access_token,
          email,
          first_name,
          group_id,
          id,
          is_designer,
          is_impersonate: false,
          last_name,
          refresh_token,
          user_discount,
        });

        const heapData = {
          userid: signup?.user?.id?.toString(),
          event: 'Create Account',
          properties: 'Website',
        };
        avo.emailAcquired({
          userId_: heapData.userid.toString(),
          email,
          location: currentPage,
          optedInMarketing: true,
          backendEmail: email,
          component: avo.Component.ACCOUNT_CREATION,
          talkativeCustomerId: getTalkativeCustomerId(),
        });
        avo.accountCreated({
          userId_: heapData.userid.toString(),
          location: currentPage,
        });

        sendAnalyticsIdentifyEvent(heapData.userid, {
          first_name,
          last_name,
          email,
        });
      }
    },
  });
  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: async data => {
        if (data && data.resetPassword) {
          if (data.resetPassword.success === true) {
            actions.onForgotPasswordSuccess();
            history.push('/sign-in');
          } else {
            setFormError(data.resetPassword.message);
            // capture invalid customer
            if (data.resetPassword.message?.includes('20007')) {
              setShowResetPassword(true);
            }
          }
        } else {
          setFormError('Unable to reset password.');
        }
      },
    }
  );

  const doSignUp = async () => {
    if (
      !firstNameValidations.isTouched ||
      firstNameValidations.isRequired ||
      firstNameValidations.minLength
    ) {
      setFormError('First name is invalid');
      return;
    }
    if (
      !lastNameValidations.isTouched ||
      lastNameValidations.isRequired ||
      lastNameValidations.minLength
    ) {
      setFormError('Last name is invalid');
      return;
    }
    if (
      !(
        passwordValidations.charLen &&
        passwordValidations.hasLower &&
        passwordValidations.hasUpper &&
        passwordValidations.hasNumber
      )
    ) {
      setFormError('Password is invalid');
      return;
    }
    await signUp({
      variables: {
        email: state.email.trim(),
        password: state.password,
        first_name: initCaps(state.first_name),
        last_name: initCaps(state.last_name),
        tempUserId: getTempUserIdCookie(),
      },
    });
  };

  const doResetPassword = async () => {
    await resetPassword({
      variables: {
        new_password: state.password,
        confirm_new_password: state.password,
        token: match.params.reset_token,
      },
    });
  };

  const onSubmit = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    if (mode === 'signup') {
      doSignUp();
    } else {
      doResetPassword();
    }
    return false;
  };

  useEffect(() => {
    let firstNameErrorMsg = '';
    let lastNameErrorMsg = '';
    if (firstNameValidations.isTouched) {
      firstNameErrorMsg = firstNameValidations.isRequired
        ? 'First name is required'
        : firstNameValidations.minLength
        ? 'Min. 2 chars are required'
        : firstNameValidations.hasInvalidCharacters
        ? 'Please remove invalid characters from name'
        : null;
    }
    if (lastNameValidations.isTouched) {
      lastNameErrorMsg = lastNameValidations.isRequired
        ? 'Last name is required'
        : lastNameValidations.minLength
        ? 'Min. 2 chars are required'
        : lastNameValidations.hasInvalidCharacters
        ? 'Please remove invalid characters from name'
        : null;
    }
    setNameErrorMsgs({
      firstNameErrorMsg,
      lastNameErrorMsg,
    });
  }, [firstNameValidations, lastNameValidations]);

  return (
    <div className="text-gray">
      <div className="consumer-create-password__content">
        <div className="text-xl font-bold">
          Create a {mode === 'forgot-password' ? ' new ' : ''} password
        </div>
        <form method="POST" onSubmit={onSubmit}>
          {mode === 'signup' && (
            <>
              <LabeledTextInput
                name="email"
                value={state.email.trim()}
                label="Email address"
                autoComplete="username"
                autoFocus={!state.email}
                onChange={evt => {
                  setEmailError(false);
                  actions.onEmailChange(evt);
                }}
                onBlur={checkEmailInput}
                errorMessage={
                  state.showEmailError || emailError
                    ? 'Email address is invalid'
                    : null
                }
              />

              <LabeledTextInput
                name="first_name"
                label="First name"
                value={state.first_name}
                autoFocus={!!state.email}
                autoComplete="given-name"
                onChange={onFirstNameChange}
                errorMessage={nameErrorMsgs.firstNameErrorMsg || ''}
              />

              <LabeledTextInput
                name="last_name"
                label="Last name"
                value={state.last_name}
                autoComplete="family-name"
                onChange={onLastNameChange}
                errorMessage={nameErrorMsgs.lastNameErrorMsg || ''}
              />
            </>
          )}

          <div>
            <div className="text-xs mb-2" id={passwordInfoId}>
              <div className="pb-[5px]">
                Your password must contain at least
              </div>
              <div
                className={cx(
                  'pb-[3px] flex items-center [&_svg_path]:fill-gray [&_svg_g.tick-stroke]:stroke-[#424242]',
                  {
                    'text-brand [&_svg_path]:!fill-brand [&_svg_g.tick-stroke]:!stroke-[#107c8c]':
                      passwordValidations.charLen === true,
                    'text-red [&_svg_path]:!fill-red [&_svg_g.tick-stroke]:!stroke-[#b85455]':
                      passwordValidations.charLen === false,
                  }
                )}
              >
                {passwordValidations.charLen ? (
                  <IconTick className="mr-1.5" />
                ) : (
                  <IconClose width={8} height={9} className="mr-2" />
                )}
                6 characters
              </div>
              <div
                className={cx(
                  'pb-[3px] flex items-center [&_svg_path]:fill-gray [&_svg_g.tick-stroke]:stroke-[#424242]',
                  {
                    'text-brand [&_svg_path]:!fill-brand [&_svg_g.tick-stroke]:!stroke-[#107c8c]':
                      passwordValidations.hasUpper === true,
                    'text-red [&_svg_path]:!fill-red [&_svg_g.tick-stroke]:!stroke-[#b85455]':
                      passwordValidations.hasUpper === false,
                  }
                )}
              >
                {passwordValidations.hasUpper ? (
                  <IconTick className="mr-1.5" />
                ) : (
                  <IconClose width={8} height={9} className="mr-2" />
                )}
                1 uppercase letter
              </div>
              <div
                className={cx(
                  'pb-[3px] flex items-center [&_svg_path]:fill-gray [&_svg_g.tick-stroke]:stroke-[#424242]',
                  {
                    'text-brand [&_svg_path]:!fill-brand [&_svg_g.tick-stroke]:!stroke-[#107c8c]':
                      passwordValidations.hasLower === true,
                    'text-red [&_svg_path]:!fill-red [&_svg_g.tick-stroke]:!stroke-[#b85455]':
                      passwordValidations.hasLower === false,
                  }
                )}
              >
                {passwordValidations.hasLower ? (
                  <IconTick className="mr-1.5" />
                ) : (
                  <IconClose width={8} height={9} className="mr-2" />
                )}
                1 lowercase letter
              </div>
              <div
                className={cx(
                  'pb-[3px] flex items-center [&_svg_path]:fill-gray [&_svg_g.tick-stroke]:stroke-[#424242]',
                  {
                    'text-brand [&_svg_path]:!fill-brand [&_svg_g.tick-stroke]:!stroke-[#107c8c]':
                      passwordValidations.hasNumber === true,
                    'text-red [&_svg_path]:!fill-red [&_svg_g.tick-stroke]:!stroke-[#b85455]':
                      passwordValidations.hasNumber === false,
                  }
                )}
              >
                {passwordValidations.hasNumber ? (
                  <IconTick className="mr-1.5" />
                ) : (
                  <IconClose width={8} height={9} className="mr-2" />
                )}
                1 number
              </div>
            </div>

            <LabeledTextInput
              aria-describedby={passwordInfoId}
              name="password"
              type="password"
              label="Password"
              value={state.password}
              autoComplete="current-password"
              onChange={onPasswordChange}
            />
          </div>

          <CtaButton
            type="submit"
            color="black"
            styling="solid-button"
            data-xc="signin-button"
            disabled={signUpLoading || resetPasswordLoading}
            block
            isLoading={signUpLoading || resetPasswordLoading}
          >
            Continue
          </CtaButton>

          {formError && (
            <>
              <div className="text-sm font-bold text-red mx-0 my-[.325rem] text-center">
                {formError}
              </div>
              {showResetPassword && (
                <div className="text-sm text-center">
                  <CtaButton
                    onClick={actions.showResetPassword}
                    hasArrow={false}
                    styling="link"
                    color="brand"
                    size="sm"
                  >
                    Reset Password
                  </CtaButton>
                </div>
              )}
            </>
          )}
        </form>

        {mode === 'signup' && (
          <>
            <div className="text-xs text-center mt-4 mx-0 mb-6 [&_a]:px-1 [&_a]:py-0">
              By creating an account, you are agreeing to our
              <CtaLink
                to="/privacy-policy/"
                color="brand"
                hasArrow={false}
                size="xs"
              >
                Privacy Policy
              </CtaLink>
              You also agree to receive exclusive sales and design inspiration
              via email; you can unsubscribe at any time.
            </div>

            <div className="text-sm text-center">
              Already have an account? &nbsp;
              <CtaButton
                onClick={actions.onLoginClick}
                color="brand"
                styling="link"
                hasArrow={false}
                size="sm"
              >
                Log in
              </CtaButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CreatePassword.propTypes = {
  mode: PropTypes.oneOf(['signup', 'forgot-password']),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.object,
  state: PropTypes.object,
  actions: PropTypes.shape({
    onForgotPasswordSuccess: PropTypes.func,
    showResetPassword: PropTypes.func,
    onFirstNameChange: PropTypes.func,
    onLastNameChange: PropTypes.func,
    onPasswordChange: PropTypes.func,
    onEmailChange: PropTypes.func,
    onLoginClick: PropTypes.func,
    onSignUp: PropTypes.func,
  }),
};

export default withRouter(CreatePassword);
