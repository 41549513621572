const getCleanDimension = dimension => {
  const cleanedDimension = dimension && parseInt(dimension, 10);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(cleanedDimension)) {
    return null;
  }

  return cleanedDimension;
};

export default getCleanDimension;
