import React from 'react';
import PropTypes from 'prop-types';
import IconStar from 'components/icons/iconStar';

const ReviewStarIcon = ({
  height = 16,
  width = 16,
  offsetValue = 0,
  starNumber,
  setHoverValue = () => {},
  hoverValue,
  rating,
}) => {
  const isHovered = hoverValue >= starNumber || rating >= starNumber;

  const onPointerEnter = () => {
    setHoverValue(starNumber);
  };
  const onPointerLeave = () => {
    setHoverValue(0);
  };

  const offset = isHovered ? 1 : offsetValue;
  return (
    <IconStar
      offsetValue={offset}
      width={width}
      height={height}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    />
  );
};

ReviewStarIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  offsetValue: PropTypes.number,
  starNumber: PropTypes.number,
  setHoverValue: PropTypes.func,
  hoverValue: PropTypes.number,
  rating: PropTypes.number,
};

export default ReviewStarIcon;
