import isTestEnv from 'commons/isTestEnv';
import useIsBrowser from 'hooks/useIsBrowser';

const isTestEnvironment = isTestEnv();

// Note: this returns true for devices that are primarily touch-based, with
// "coarse" pointers such as mobile phones and iPads, and returns false
// for devices with only "fine" pointers (primarily mouse or trackpad based),
// such as a laptop with a touchscreen.
const useIsTouchDevice = () => {
  const isBrowser = useIsBrowser();

  // Return false on initial render on both client and server to prevent
  // hydration errors, then return the result of the isTouchDevice check once
  // the component has mounted in the browser.
  if (!isBrowser || isTestEnvironment) {
    return false;
  }

  return (
    ('ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0) &&
    window?.matchMedia &&
    window?.matchMedia('(pointer: coarse)').matches
  );
};

export default useIsTouchDevice;
