// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const GET_CUSTOMER_IS_EXISTS = gql`
  query getCustomerIsExists($email: String!) {
    getCustomerIsExists(email: $email) {
      exists
      errors {
        code
        message
      }
    }
  }
`;
