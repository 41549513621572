import React from 'react';
import PropTypes from 'prop-types';

const getFormatter = (currency, showDecimals) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: showDecimals ? 2 : 0,
  });

const Currency = ({ currency = 'USD', className = '', children }) => {
  if (
    typeof children === 'undefined' ||
    children === null ||
    Number.isNaN(children)
  ) {
    return <span className={`Currency ${className}`} />;
  }

  const showDecimals = parseInt(children, 10) !== parseFloat(children);
  const currencyString = getFormatter(currency, showDecimals).format(children);

  return <span className={`Currency ${className}`}>{currencyString}</span>;
};

Currency.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  currency: PropTypes.string,
};

export default Currency;
