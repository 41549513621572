/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useOnEnterOrSpacebar from 'hooks/useOnEnterOrSpacebar';

const RatioContainer = React.forwardRef(
  (
    { children, className = '', onClick, ratio = 1, wrapperClassName = '' },
    ref
  ) => {
    const innerElementRef = useRef(null);
    useOnEnterOrSpacebar(!!onClick, innerElementRef, onClick);

    return (
      <div
        className={`h-0 overflow-hidden relative ${wrapperClassName}`}
        style={{ paddingTop: `${100 * ratio}%` }}
        ref={ref}
      >
        <div
          className={`h-full left-0 absolute top-0 w-full ${className}`}
          onClick={onClick}
          role={onClick ? 'button' : undefined}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={onClick ? '0' : undefined}
          ref={innerElementRef}
        >
          {children}
        </div>
      </div>
    );
  }
);

RatioContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  ratio: PropTypes.number,
  wrapperClassName: PropTypes.string,
};

export default RatioContainer;
