const getAltFromSrc = src => {
  try {
    const tokens = src
      .split('/')
      .pop()
      .split('?')[0]
      .split('.')
      .slice(0, -1)
      .join(' ')
      .replace(/-|_/g, ' ')
      .split(' ');
    /*
        Filter tokens of following patterns:
        /^[A-Z]{1,3}[0-9]{1,5}$/g - tokens of pattern CF200
        /^[0-9]{0,6}$/g - numbers less than 6 digits
        /^.{0,2}$/g - less than 2 char tokens
      */
    const filteredTokens = tokens.filter(
      token => !/^[A-Z]{1,3}[0-9]{1,5}$|^[0-9]{0,6}$|^.{0,2}$/g.test(token)
    );
    if (filteredTokens.length > 0) {
      return filteredTokens.join(' ');
    }
    return tokens.join(' ');
  } catch (err) {
    // ignore
  }
  return null;
};

export default getAltFromSrc;
