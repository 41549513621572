import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';

import isClient from 'commons/isClient';
import { EMAIL_REGEX } from 'commons/constants';
import CtaButton from 'components/essentials/Cta/CtaButton';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import FacebookLogin from '../fb-signup/FacebookLogin';
import { GET_CUSTOMER_IS_EXISTS } from '../queries';

const PreLogin = ({
  state = { email: '' },
  actions,
  isLoginPage,
  isFbLoading,
  staticContext,
  showGuestLogin,
  onGuestLoginClick,
}) => {
  const [fromURL, setFromURL] = useState('');
  const inputRef = useRef(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const location = useLocation();

  // lazy query
  const [
    getCustomerIsExists,
    { loading: customerExistsLoading, data: customerIsExists },
  ] = useLazyQuery(GET_CUSTOMER_IS_EXISTS, {
    fetchPolicy: 'no-cache',
  });

  // on mount
  useEffect(() => {
    // get code
    if (isClient() && location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('from')) {
        setFromURL(params.get('from'));
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (customerIsExists && customerIsExists.getCustomerIsExists) {
      if (customerIsExists.getCustomerIsExists.errors) {
        setIsEmailValid(false);

        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      } else {
        setIsEmailValid(true);
        actions.onCustomerExistsUpdate(
          customerIsExists.getCustomerIsExists.exists
        );
      }
    }
  }, [customerIsExists, actions]);

  const onSubmit = async evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const isUpdatedEmailValid = EMAIL_REGEX.test(
      String(state.email.trim()).toLowerCase()
    );

    if (!isUpdatedEmailValid) {
      setIsEmailValid(false);
      return false;
    }

    await getCustomerIsExists({
      variables: {
        email: state.email.trim(),
      },
    });

    return false;
  };
  let redirectUri = '';
  // is LoginPage
  if (isLoginPage) {
    redirectUri = isClient() ? window.location.href : '/';
  } else {
    redirectUri = `${isClient() ? window.location.origin : ''}/sign-in`;
  }

  return (
    <div className="text-gray">
      <div>
        {fromURL && fromURL.length > 0 && (
          <div className="return-link">
            <a href={fromURL}>&larr; Return</a>
          </div>
        )}

        <h2 className="text-xl font-bold text-gray m-0">
          Log in or create account
        </h2>

        <FacebookLogin
          redirectUri={redirectUri}
          onFBCallback={actions.onFBCallback}
          staticContext={staticContext}
          isFbLoading={isFbLoading}
        />
        <div
          className={classNames(
            '[background:none] h-3.5 relative text-center mt-[.825rem] mb-[1.875rem]',
            '[&_hr]:border-0 [&_hr]:![border-top:1px_solid_#f1f1f1] [&_hr]:mt-[-13px] [&_hr]:mx-auto [&_hr]:mb-2.5 [&_hr]:w-full'
          )}
        >
          <span className="text-xs inline-block relative -top-1.5 text-gray bg-gray-light9">
            or
          </span>
          <hr aria-hidden="true" />
        </div>
        <form method="POST" onSubmit={onSubmit}>
          <LabeledTextInput
            name="email"
            value={state.email.trim()}
            label="Email address"
            autoComplete="username"
            onChange={evt => {
              setIsEmailValid(true);
              actions.onEmailChange(evt);
            }}
            onBlur={evt => {
              const emailValid = EMAIL_REGEX.test(
                String(evt.target.value.trim()).toLowerCase()
              );
              setIsEmailValid(emailValid);
            }}
            errorMessage={
              !isEmailValid ? 'Please enter a valid email address' : null
            }
            ref={inputRef}
          />

          <CtaButton
            type="submit"
            color="black"
            styling="solid-button"
            data-xc="signin-button"
            disabled={customerExistsLoading || !isEmailValid}
            block
            isLoading={customerExistsLoading}
          >
            Continue
          </CtaButton>
        </form>
        {!!showGuestLogin && (
          <>
            <div
              className={classNames(
                '[background:none] h-3.5 relative text-center mt-[.825rem] mb-0',
                '[&_hr]:border-0 [&_hr]:![border-top:1px_solid_#f1f1f1] [&_hr]:mt-[-13px] [&_hr]:mx-auto [&_hr]:mb-2.5 [&_hr]:w-full'
              )}
            >
              <span className="text-xs inline-block relative -top-1.5 text-gray bg-gray-light9">
                or
              </span>
              <hr aria-hidden="true" />
            </div>
            <div className="text-center">
              <CtaButton
                size="sm"
                styling="link"
                color="brand"
                hasArrow
                onClick={onGuestLoginClick}
              >
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Don't want an account? Continue as guest
              </CtaButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
PreLogin.propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string,
  }),
  actions: PropTypes.shape({
    onFBCallback: PropTypes.func,
    onEmailChange: PropTypes.func,
    onCustomerExistsUpdate: PropTypes.func,
  }),
  isLoginPage: PropTypes.bool,
  isFbLoading: PropTypes.bool,
  staticContext: PropTypes.shape({}),
  showGuestLogin: PropTypes.bool,
  onGuestLoginClick: PropTypes.func,
};
export default PreLogin;
