import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useRandomId from 'hooks/useRandomId';
import ReviewStarIcon from './components/ReviewStarIcon';
import VisuallyHidden from '../VisuallyHidden';

const getStars = (rating = 0) => {
  const starValues = [];
  for (let i = 0; i < 5; i++) {
    let value;
    if (i < Math.floor(rating)) {
      value = 1;
    } else if (i === Math.floor(rating)) {
      value = parseFloat((rating - Math.floor(rating)).toFixed(1));
    } else {
      value = 0;
    }
    starValues.push({
      key: `star${i + 1}`,
      value,
    });
  }
  return starValues;
};

const ReviewStars = ({
  errorMessage,
  rating = 0,
  noSpacing = false,
  readOnly = false,
  size = 'lg',
  onChange,
}) => {
  const [hoverValue, setHoverValue] = useState(null);
  const starValues = useMemo(() => getStars(rating), [rating]);
  const iconSize =
    {
      xs: 12,
      sm: 16,
      md: 20,
      lg: 24,
      xl: 32,
      xxl: 40,
    }[size] || 40;

  const inputId = useRandomId();

  return (
    <div className="flex flex-col justify-center relative">
      <div
        className={classNames(
          `border-solid border-2 border-transparent justify-center p-0`,
          {
            'rounded-sm': errorMessage,
          }
        )}
      >
        {!!readOnly && !!rating && (
          <VisuallyHidden>Rating: {rating} out of 5 stars</VisuallyHidden>
        )}

        <div
          className={classNames('flex flex-row', {
            'gap-2': !noSpacing,
          })}
        >
          {starValues.map((starValue, index) => (
            <label key={starValue?.key} htmlFor={`${starValue?.key}${inputId}`}>
              <ReviewStarIcon
                offsetValue={starValue?.value}
                width={iconSize}
                height={iconSize}
                starNumber={index + 1}
                hoverValue={!readOnly ? hoverValue : undefined}
                setHoverValue={setHoverValue}
                rating={rating}
              />
              <input
                id={`${starValue?.key}${inputId}`}
                onChange={onChange}
                type="radio"
                name={`starInput-${inputId}`}
                value={index + 1}
                disabled={readOnly}
                hidden
              />
            </label>
          ))}
        </div>
      </div>

      {errorMessage && <span className="text-xs text-red">{errorMessage}</span>}
    </div>
  );
};

ReviewStars.propTypes = {
  errorMessage: PropTypes.string,
  rating: PropTypes.number,
  readOnly: PropTypes.bool,
  noSpacing: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  onChange: PropTypes.func,
};

export default ReviewStars;
