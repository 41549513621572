import PropTypes from 'prop-types';

// This component is used to render loading UI for components when they are used
// within the legacy consumer app. Once the initial migration to the new
// consumer app is complete, this component can be removed.

const SuspenseWithLoaderLegacy = ({
  children,
  fallback,
  isLoading = false,
}) => {
  if (isLoading) {
    return fallback;
  }

  return children;
};

SuspenseWithLoaderLegacy.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default SuspenseWithLoaderLegacy;
