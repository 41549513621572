import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import Imgix, { Source } from 'react-imgix';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import mapImageURLToRootDomain from 'commons/mapImageURLToRootDomain';
import getAltFromSrc from 'commons/getAltFromSrc';
import removeNull from 'commons/removeNull';
import { useEffectOnce, usePrevious } from 'react-use';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import useSetBrokenImageUrl from 'global-state/brokenImageUrls/useSetBrokenImageUrl';
import ReactiveImage from './ReactiveImage';
import { DEFAULT_IMGIX_PARAMS, TRANSPARENT_FILLER_IMAGE } from './constants';
import getCleanDimension from './helpers/getCleanDimension';
import DefaultFallbackImage from './DefaultFallbackImage';

// In the new Consumer app, lazy loading images with lazySizes is unnecessary so
// we disable it and mark all images as loaded by default
const hasLoadedDefault = !!isNewConsumerApp;

const JBImage = props => {
  const [hasLoaded, setHasLoaded] = useState(hasLoadedDefault);
  const [errorLoadingMainImage, setErrorLoadingMainImage] = useState(false);
  const [errorLoadingFallback1, setErrorLoadingFallback1] = useState(false);
  const [errorLoadingFallback2, setErrorLoadingFallback2] = useState(false);

  const {
    ar,
    auto,
    bg,
    className,
    crop,
    cropFormat,
    crops,
    enableCrops,
    fallbackSrc: fallbackSrc1,
    fallbackSrc2,
    fill,
    fillColor,
    fit,
    fm,
    forPage = '',
    fpX,
    fpY,
    fpZoom,
    height: heightProp,
    htmlAttributes = {},
    imgRef,
    isProductImage,
    lazy: lazyProp = true,
    loader,
    onError: onErrorProp,
    onLoad,
    pad,
    quality,
    rect,
    renderAsSource,
    sizes,
    src: srcProp,
    trim,
    trimColor,
    trimPad,
    trimTol,
    width: widthProp,
    ...otherProps
  } = props;

  const lazy = !isNewConsumerApp ? lazyProp : false;

  // If alt is undefined, automatically generate alt text from src, but if alt
  // is defined or an empty string it should be passed through to the img tag
  const { alt: altProp } = props;
  const altFromSrc = useMemo(() => getAltFromSrc(srcProp), [srcProp]);
  const alt = altProp ?? altFromSrc;

  const defaultRef = useRef();
  const ref = imgRef || defaultRef;
  const prevSrcProp = usePrevious(srcProp);

  // If there is an error loading main image and fallbackSrc1 is defined, try
  // to show the first fallback image
  const showFallbackImage1 = errorLoadingMainImage && !!fallbackSrc1;

  // If there is an error loading main image, an error loading first fallback
  // image, useDefaultFallback is false and fallbackSrc2 is defined, try to show
  // the second fallback image
  const showFallbackImage2 =
    errorLoadingMainImage && errorLoadingFallback1 && !!fallbackSrc2;

  // If there were errors loading main image, fallback 1 (when supplied) and
  // fallback 2 (when supplied), then show the default fallback image
  const showDefaultFallbackImage =
    errorLoadingMainImage &&
    ((showFallbackImage1 && errorLoadingFallback1) || !showFallbackImage1) &&
    ((showFallbackImage2 && errorLoadingFallback2) || !showFallbackImage2);

  useEffect(() => {
    if (prevSrcProp !== srcProp) {
      // If the src prop changes, reset the error state
      setErrorLoadingMainImage(false);
      setErrorLoadingFallback1(false);
      setErrorLoadingFallback2(false);
    }
  }, [prevSrcProp, srcProp]);

  const onImageLoad = useCallback(() => {
    if (!ref?.current?.src || ref?.current?.src === TRANSPARENT_FILLER_IMAGE) {
      // src is not set yet by the lazySizes, ignore this callback
      return;
    }

    setHasLoaded(true);
    onLoad?.();
  }, [onLoad, ref]);

  const setBrokenImageUrl = useSetBrokenImageUrl();

  const onError = useCallback(
    event => {
      setBrokenImageUrl(ref?.current?.src);

      if (showFallbackImage2) {
        setErrorLoadingFallback2(true);
      } else if (showFallbackImage1) {
        setErrorLoadingFallback1(true);
      } else {
        setErrorLoadingMainImage(true);
      }

      onErrorProp?.(event);

      if (forPage === 'pdp' && !fallbackSrc1) {
        onLoad?.(true);
      }
    },
    [
      fallbackSrc1,
      forPage,
      onErrorProp,
      onLoad,
      ref,
      setBrokenImageUrl,
      showFallbackImage1,
      showFallbackImage2,
    ]
  );

  useEffectOnce(() => {
    if (ref.current?.complete) {
      onImageLoad();
    }
  });

  useEffect(() => {
    if (showFallbackImage1) {
      onLoad?.(forPage === 'pdp' ?? null);
    }
  }, [forPage, showFallbackImage1, onLoad]);

  const src = useMemo(() => {
    if (showFallbackImage2) {
      return fallbackSrc2;
    }

    if (showFallbackImage1) {
      return fallbackSrc1;
    }

    return srcProp;
  }, [
    fallbackSrc1,
    fallbackSrc2,
    showFallbackImage1,
    showFallbackImage2,
    srcProp,
  ]);

  if (srcProp?.startsWith?.('data:image/') && !showFallbackImage1) {
    return (
      <img
        alt={alt}
        src={srcProp}
        className={className || ''}
        {...omit(props, ['src', 'fallbackSrc'])}
      />
    );
  }

  if (!src?.split?.('://')[1] || showDefaultFallbackImage) {
    return (
      <DefaultFallbackImage className={className} brokenImageSrc={srcProp} />
    );
  }

  const width = getCleanDimension(widthProp);
  const height = getCleanDimension(heightProp);

  const finalProps = removeNull({
    ...{
      src: mapImageURLToRootDomain(decodeURI(src)),
      width: renderAsSource ? null : width,
      height: renderAsSource ? null : height,
      className: renderAsSource
        ? null
        : classNames(className, 'jb-image opacity-0', {
            'animate-placeholder-shimmer [background-color:#f6f7f8] [background:linear-gradient(90deg,#eee_8%,#ddd_18%,#eee_33%)] [background-size:800px_104px] relative':
              loader && !hasLoaded,
            '!opacity-100': loader || !lazy || hasLoaded,
            lazyload: lazy,
          }),
      attributeConfig:
        lazy === false
          ? null
          : {
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            },
      onLoad: onImageLoad,
      sizes,
    },
    imgixParams: {
      ...DEFAULT_IMGIX_PARAMS,
      ...removeNull({
        ar,
        auto,
        bg,
        crop,
        fill,
        'fill-color': fillColor,
        fit,
        fm,
        'fp-x': fpX,
        'fp-y': fpY,
        'fp-z': fpZoom,
        pad,
        quality,
        rect,
        trim,
        'trim-color': trimColor,
        'trim-pad': trimPad,
        'trim-tol': trimTol,
        ...crops?.[cropFormat],
      }),
    },
    imgProps: removeNull({
      ...otherProps,
      alt: renderAsSource ? null : alt,
      ref,
      src: lazy ? TRANSPARENT_FILLER_IMAGE : null,
    }),
  });

  if (renderAsSource) {
    return (
      <Source
        {...finalProps}
        htmlAttributes={{
          ...finalProps.imgProps,
          ...htmlAttributes,
        }}
      />
    );
  }

  if (!width && !height && !sizes) {
    return <ReactiveImage {...finalProps} />;
  }

  return (
    <Imgix
      {...finalProps}
      htmlAttributes={{
        ...finalProps.imgProps,
        onLoad: onImageLoad,
        onError,
        ...htmlAttributes,
      }}
    />
  );
};

JBImage.propTypes = {
  alt: PropTypes.string,
  ar: PropTypes.string,
  auto: PropTypes.string,
  bg: PropTypes.string,
  className: PropTypes.string,
  crop: PropTypes.oneOf(['entropy', 'focalpoint', 'center']),
  cropFormat: PropTypes.string,
  crops: PropTypes.object,
  enableCrops: PropTypes.bool,
  fallbackSrc: PropTypes.string,
  fallbackSrc2: PropTypes.string,
  fill: PropTypes.string,
  fillColor: PropTypes.string,
  fit: PropTypes.oneOf(['clip', 'crop', 'fill', 'max']),
  fm: PropTypes.string,
  forPage: PropTypes.string,
  fpX: PropTypes.number,
  fpY: PropTypes.number,
  fpZoom: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  htmlAttributes: PropTypes.object,
  imgRef: PropTypes.object,
  isProductImage: PropTypes.bool,
  lazy: PropTypes.bool,
  loader: PropTypes.bool,
  media: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  pad: PropTypes.number,
  quality: PropTypes.number,
  rect: PropTypes.string,
  renderAsSource: PropTypes.bool,
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  trim: PropTypes.string,
  trimColor: PropTypes.string,
  trimPad: PropTypes.number,
  trimTol: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default JBImage;
