import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntersection } from 'react-use';

const MobileModalContentUnderHeader = ({
  children,
  onIntersectionChange = () => {},
}) => {
  const contentUnderHeaderRef = useRef(null);
  const [isMajorityOfContentVisible, setIsMajorityOfContentVisible] = useState(
    false
  );
  const intersection = useIntersection(contentUnderHeaderRef, {
    threshold: 0.6,
  });

  if (
    intersection &&
    isMajorityOfContentVisible !== intersection.isIntersecting
  ) {
    setIsMajorityOfContentVisible(intersection.isIntersecting);
    onIntersectionChange(intersection.isIntersecting);
  }

  return <div ref={contentUnderHeaderRef}>{children}</div>;
};

MobileModalContentUnderHeader.propTypes = {
  children: PropTypes.node,
  onIntersectionChange: PropTypes.func,
};

export default MobileModalContentUnderHeader;
