import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import getFallbackDebugMessage from './getFallbackDebugMessage';

// This component is used to render loading UI for components when they are used
// within the new consumer app. Once the initial migration to the new consumer
// app is complete, this component will replace the conditional rendering logic
// in SuspenseWithLoader.

// Set to true to render a debugging message when loading instead of the
// specified fallback component.
const SHOW_DEBUG_FALLBACKS = false;

const SuspenseWithLoaderNew = ({ children, fallback }) => {
  const fallbackToShow = SHOW_DEBUG_FALLBACKS
    ? getFallbackDebugMessage(children)
    : fallback;

  return <Suspense fallback={fallbackToShow}>{children}</Suspense>;
};

SuspenseWithLoaderNew.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default SuspenseWithLoaderNew;
