import React from 'react';
import PropTypes from 'prop-types';
import { colorYellow, colorYellowDark } from 'styles/layout/colors';
import useRandomId from 'hooks/useRandomId';

const IconStar = ({
  className = '',
  fill = colorYellow,
  height = 16,
  stroke = colorYellowDark,
  width = 16,
  offsetValue = 0,
  onPointerEnter,
  onPointerLeave,
}) => {
  const svgId = useRandomId('star-gradient-');
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={className}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <defs>
        <linearGradient id={svgId}>
          <stop offset="0%" stopColor={fill} />
          <stop offset={`${offsetValue * 100.0}%`} stopColor={fill} />
          <stop offset={`${offsetValue * 100.0}%`} stopColor="white" />
          <stop offset="100%" stopColor="white" />
        </linearGradient>
      </defs>
      <path
        d="M9.945 4.62l-.018-.039L8.435.768C8.264.378 7.793.362 7.68.67L6.077 4.532c-.27.547-.692.902-1.197.9l-3.964.356c-.424.042-.573.58-.241.916l2.928 2.69c.385.389.526.923.398 1.44l-.88 4c-.091.459.318.76.656.547l3.425-2.128c.434-.292 1.096-.292 1.514-.01l3.443 2.14c.336.212.745-.09.656-.539l-.879-3.998a1.52 1.52 0 01.412-1.468l3.01-2.773c.282-.244.112-.777-.235-.816l-4.059-.282c-.511-.129-.884-.411-1.12-.886z"
        stroke={stroke}
        fill={`url('#${svgId}')`}
        fillRule="nonzero"
      />
    </svg>
  );
};

IconStar.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  stroke: PropTypes.string,
  width: PropTypes.number,
  offsetValue: PropTypes.number,
  onPointerEnter: PropTypes.func,
  onPointerLeave: PropTypes.func,
};

export default IconStar;
