import React from 'react';
import PropTypes from 'prop-types';

const JBLogo = ({ className = '', idSuffix = '' }) => (
  <svg
    width="100"
    height="20"
    viewBox="0 0 100 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id={`JBLogo${idSuffix}`}
    role="img"
    aria-labelledby={`JBLogo${idSuffix}__title`}
    className={`JBLogo--standard-logo ${className}`}
  >
    <title id={`JBLogo${idSuffix}__title`}>Joybird</title>
    <path
      d="M1.85028 15.0447C2.09164 15.357 2.38294 15.6253 2.71186 15.8384C2.97621 16.001 3.279 16.0868 3.58757 16.0866C3.81878 16.1094 4.05172 16.0678 4.26163 15.9661C4.47153 15.8645 4.65055 15.7067 4.77966 15.5094C5.0113 15.1246 5.12712 14.4185 5.12712 13.3911V0.193359H7.9887V14.3521C7.9887 15.8913 7.65725 17.0216 6.99435 17.7431C6.33145 18.4646 5.29567 18.8254 3.88701 18.8254C3.1588 18.8364 2.43752 18.6796 1.77684 18.3665C1.09885 18.0161 0.495078 17.5326 0 16.9437L1.84746 15.0447H1.85028Z"
      fill="#016A78"
    />
    <path
      d="M27.2006 9.50072C27.2035 8.62471 27.0404 7.75641 26.7204 6.94372C26.4037 6.13898 25.9343 5.40627 25.339 4.78787C24.7657 4.16877 24.0732 3.67749 23.3051 3.34487C22.1434 2.83726 20.8579 2.70344 19.6199 2.96124C18.3819 3.21905 17.2502 3.85624 16.3757 4.78787C15.7798 5.39849 15.3109 6.12591 14.9972 6.9264C14.6797 7.74372 14.5186 8.61564 14.5226 9.49495C14.5181 10.3677 14.6792 11.2332 14.9972 12.0433C15.3169 12.8452 15.7883 13.5748 16.3842 14.1905C16.9642 14.8094 17.6622 15.3005 18.4351 15.6335C19.2086 15.9652 20.0397 16.134 20.8786 16.1299C21.7072 16.1323 22.5278 15.9635 23.291 15.6335C24.0632 15.2963 24.7623 14.8058 25.3475 14.1905C25.9426 13.5742 26.4122 12.8434 26.7288 12.0404C27.0492 11.2338 27.2123 10.3711 27.2091 9.50072H27.2006ZM30.1441 9.50072C30.1534 10.7285 29.9174 11.9452 29.4506 13.0766C28.9839 14.208 28.2961 15.2303 27.4294 16.0808C26.5571 16.9462 25.53 17.6321 24.404 18.101C23.2824 18.5696 22.0818 18.8088 20.8701 18.8052C19.6443 18.8098 18.4294 18.5696 17.2938 18.0981C16.1708 17.6332 15.1485 16.9467 14.2853 16.0779C13.4203 15.2313 12.7339 14.2128 12.2684 13.0851C11.3493 10.7858 11.3493 8.20991 12.2684 5.91053C12.7326 4.77286 13.4189 3.74375 14.2853 2.886C15.1482 2.01549 16.1718 1.3288 17.2966 0.865797C18.4334 0.403553 19.6466 0.169368 20.8701 0.176042C22.0862 0.169879 23.2919 0.4041 24.4209 0.865797C25.5434 1.32743 26.5657 2.01095 27.4291 2.87714C28.2924 3.74333 28.9799 4.77516 29.452 5.91342C29.9148 7.04391 30.1502 8.25802 30.1441 9.4834V9.50072Z"
      fill="#016A78"
    />
    <path
      d="M36.9718 18.808V10.2886L30.839 0.187561H33.9463L37.7655 6.50502C37.8616 6.66664 37.9746 6.8802 38.1102 7.14283C38.2458 7.40545 38.3927 7.68828 38.5283 8.00863C38.6412 7.72003 38.7655 7.43143 38.8927 7.16592C39.016 6.91747 39.1518 6.67569 39.2994 6.44153L43.2203 0.193332H46.1808L39.904 10.2943V18.8138L36.9718 18.808Z"
      fill="#016A78"
    />
    <path
      d="M51.065 8.05197H52.1949C53.6073 8.05197 54.6045 7.85861 55.1892 7.47477C55.774 7.09093 56.065 6.41561 56.065 5.48054C56.065 4.46467 55.7956 3.7499 55.257 3.33624C54.7175 2.92354 53.6977 2.72152 52.1949 2.72152H51.065V8.05197V8.05197ZM51.065 16.2771H52.0847C52.8625 16.2915 53.6403 16.25 54.4124 16.153C54.8453 16.0963 55.2608 15.9436 55.6299 15.7056C55.995 15.4603 56.288 15.1181 56.4774 14.7157C56.6746 14.2854 56.7713 13.8142 56.7599 13.3391C56.773 12.7992 56.6484 12.2651 56.3983 11.7893C56.1611 11.3506 55.8024 10.9932 55.3672 10.7619C55.062 10.6075 54.7366 10.4987 54.4011 10.4387C53.9262 10.3658 53.4463 10.3329 52.9661 10.3406H51.0508V16.2771H51.065ZM48.1638 18.8081V0.193383H52.7542C53.7278 0.164962 54.7017 0.237483 55.661 0.409833C56.2366 0.529975 56.7812 0.771808 57.2599 1.11979C57.8298 1.55157 58.2812 2.12607 58.5706 2.7879C58.8879 3.50179 59.0479 4.2781 59.0395 5.06207C59.0702 5.9421 58.8355 6.81052 58.3672 7.54981C57.8941 8.23316 57.203 8.72701 56.4124 8.94663C56.9059 9.01095 57.3819 9.17468 57.813 9.42832C58.2441 9.68197 58.6216 10.0205 58.9237 10.4243C59.5569 11.324 59.8789 12.4135 59.839 13.5209C59.8436 14.2552 59.7123 14.9837 59.452 15.6681C59.2141 16.3198 58.84 16.9107 58.3559 17.3997C57.8438 17.9253 57.2043 18.3028 56.5028 18.4935C55.7627 18.7071 54.5216 18.8129 52.7796 18.811L48.1638 18.8081Z"
      fill="#016A78"
    />
    <path
      d="M62.9011 18.8081V0.193359H65.8616V18.8081H62.9011Z"
      fill="#016A78"
    />
    <path
      d="M73.0791 8.12696H73.6158C75.1826 8.12696 76.2184 7.94034 76.7231 7.56708C77.2373 7.1919 77.4971 6.53389 77.4971 5.58439C77.4971 4.5589 77.2212 3.83644 76.6695 3.41701C76.1177 2.99757 75.0998 2.78786 73.6158 2.78786H73.0791V8.12696V8.12696ZM72.8813 10.4791V18.8081H70.1073V0.19334H74.2372C75.1847 0.168264 76.132 0.249559 77.0621 0.435764C77.7306 0.575048 78.3554 0.879666 78.8819 1.32294C79.4083 1.7662 79.8204 2.3346 80.0819 2.97833C80.3924 3.71742 80.5482 4.51465 80.5395 5.31888C80.5395 6.80614 80.1873 7.97401 79.483 8.82249C78.7768 9.66232 77.7316 10.1703 76.3559 10.3405L82.6299 18.8081H79.2909L73.2288 10.4791H72.8813Z"
      fill="#016A78"
    />
    <path
      d="M87.3559 16.075H89.8446C90.8786 16.1064 91.9125 16.0095 92.9237 15.7864C93.5821 15.6136 94.1941 15.2921 94.7147 14.8456C95.4033 14.239 95.9342 13.4676 96.2599 12.6003C96.6224 11.6018 96.7978 10.5424 96.7768 9.47763C96.7976 8.41284 96.6222 7.35356 96.2599 6.35498C95.9342 5.48848 95.4033 4.71801 94.7147 4.11255C94.174 3.66304 93.5418 3.34259 92.8644 3.1746C91.7561 2.94994 90.6266 2.85313 89.4972 2.886H87.3559V16.075ZM84.3955 18.8081V0.193359H88.387C90.9859 0.193359 92.8145 0.326116 93.8729 0.591628C94.8814 0.83705 95.829 1.29406 96.6554 1.93362C97.7107 2.76078 98.5364 3.85505 99.0508 5.10822C99.6164 6.50733 99.8943 8.01033 99.8672 9.52381C99.8956 11.0309 99.6177 12.5275 99.0508 13.9192C98.531 15.1692 97.7063 16.2622 96.6554 17.0938C95.8471 17.7251 94.9216 18.1817 93.935 18.4358C92.9152 18.6984 91.3361 18.8341 89.1864 18.8341H84.3842L84.3955 18.8081Z"
      fill="#016A78"
    />
  </svg>
);

JBLogo.propTypes = {
  className: PropTypes.string,
  idSuffix: PropTypes.string,
};

export default JBLogo;
