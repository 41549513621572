/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import { withContentRect } from 'react-measure';

import transparentImage from './transparent-min.png';

class ReactiveImageInner extends React.Component {
  state = {};

  shouldComponentUpdate() {
    if (this.state.brokenImage) {
      return false;
    }
    return true;
  }

  onImageError = () => {
    this.setState({ brokenImage: true });
  };

  render() {
    const {
      contentRect,
      htmlAttributes,
      loader,
      measure,
      measureRef,
      ...other
    } = this.props;

    if (!contentRect.bounds.height) {
      return (
        <img
          ref={measureRef}
          alt=""
          {...other.imgProps}
          src={transparentImage}
        />
      );
    }

    let { width } = contentRect.bounds;
    width = Math.ceil(width);

    return (
      <Imgix
        {...other}
        width={width}
        htmlAttributes={{
          ref: measureRef,
          ...other.imgProps,
          onError: this.onImageError,
          onLoad: other.onLoad,
          ...htmlAttributes,
        }}
      />
    );
  }
}

ReactiveImageInner.propTypes = {
  contentRect: PropTypes.shape({
    bounds: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
  htmlAttributes: PropTypes.object,
  isLQIPEligible: PropTypes.bool,
  loader: PropTypes.func,
  measure: PropTypes.func,
  measureRef: PropTypes.func,
};

const ReactiveImage = withContentRect('bounds')(ReactiveImageInner);

export default ReactiveImage;
