/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useScreen from 'hooks/useScreen';
import Placeholder from 'components/consumer/Placeholder';
import RatioContainer from 'components/consumer/RatioContainer';
import IconTick from 'components/icons/iconTick';
import IconHeart2 from 'components/icons/iconHeart2';
import IconLightningBoltFilled from 'components/icons/IconLightningBoltFilled';
import useIsTouchDevice from 'hooks/useIsTouchDevice';

const MaterialThumbnailUI = ({
  customToggleFlyOut = false,
  customToggleFlyOutData = null,
  fullHeightThumbnail = false,
  image,
  isDisabled = false,
  isFavMaterial = false,
  isInFlashSale = false,
  isRound = false,
  isSelected = false,
  label,
  loading = false,
  materialApplicableForProduct,
  materialId,
  onClick,
  onDblClick,
  onHover,
  toggleFlyOut = () => {},
  variant,
}) => {
  const isTouchDevice = useIsTouchDevice();
  const { isDesktop, isMobile } = useScreen();

  const hasClickHandler = !!onClick;

  return (
    <RatioContainer
      ratio={1}
      className={classNames('MaterialThumbnailUI__wrapper', {
        'hover:cursor-pointer': hasClickHandler,
      })}
      wrapperClassName={classNames('MaterialThumbnailUI__outer-wrapper', {
        'h-full': fullHeightThumbnail,
        '!pt-0 h-[59px]': variant === 'PDPCustomerPhotosReviewsModal',
      })}
    >
      <Placeholder
        loading={loading}
        type="rect"
        className="border-2 border-solid border-white rounded-lg h-full w-full cursor-auto shadow-[inset_0_0_0_5px_#fff]"
      >
        <div
          className={classNames(
            'border-2 border-solid border-white rounded-lg h-full w-full',
            'first:shadow-[inset_0_0_0_1px_#f6f6f6,inset_0_0_0_4px_#fff]',
            {
              '!border-0': variant === 'PDPShownInUI--grey-background',
              'shadow-[inset_0_0_0_1px_#737373,inset_0_0_0_4px_#fff]': isSelected,
            }
          )}
          onMouseEnter={() => {
            if (isDesktop) {
              if (customToggleFlyOut && customToggleFlyOutData) {
                toggleFlyOut(true, customToggleFlyOutData);
              } else {
                toggleFlyOut(true, materialId);
              }
            }

            if (!isMobile && onHover && materialApplicableForProduct) {
              onHover();
            }
          }}
          onMouseLeave={() => {
            if (isDesktop) {
              toggleFlyOut(false, materialId);
            }
          }}
        >
          <div
            onClick={evt => {
              if (onClick) {
                onClick(evt);
              }
            }}
            onDoubleClick={evt => {
              if (!isTouchDevice && onDblClick) {
                onDblClick(evt);
              }
            }}
            className={classNames(
              'items-center rounded-lg flex h-full justify-center [transition:box-shadow_25ms_ease-in] w-full',
              {
                '!rounded-[50%]':
                  variant === 'PDPShownInUI' ||
                  variant === 'PDPShownInUI--grey-background',
                '!shadow-[inset_0_0_0_1px_#f1f1f1,inset_0_0_0_2px_#f1f1f1]':
                  variant === 'PDPShownInUI--grey-background',
                'relative !shadow-[inset_0_0_0_1px_#262626,inset_0_0_0_3px_#fff]': isDisabled,
                '!shadow-[inset_0_0_0_1px_#262626,inset_0_0_0_4px_#fff]': isSelected,
                'rounded-full': isRound,
                'shadow-[inset_0_0_0_1px_#fff,inset_0_0_0_4px_#fff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-brand':
                  hasClickHandler && !isRound,
                'hover:shadow-[inset_0_0_0_1px_#737373,inset_0_0_0_4px_#fff]':
                  hasClickHandler && !isRound && !isSelected,
                '!rounded-none': variant === 'PDPFabricUpChargeModal',
              }
            )}
            style={
              image
                ? {
                    background: `url(${image}?auto=format%2Ccompress&w=50&h=50&dpr=2&cs=srgb&rect=0,0,100,100) 0% 0% / 100% 100% no-repeat`,
                  }
                : undefined
            }
            aria-checked={isSelected}
            aria-label={onClick ? `Select ${label}` : label}
            role={hasClickHandler ? 'radio' : undefined}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={hasClickHandler ? '0' : undefined}
            title={onClick ? `Select ${label}` : label}
          >
            <div
              className={classNames('hidden', {
                'block z-[1] [&_svg]:absolute [&_svg]:bottom-2 [&_svg]:right-2 [&_svg]:w-[.6rem] [&_svg]:h-[.6rem]': isFavMaterial,
                '[&_svg]:w-[.6rem] [&_svg]:h-[.6rem]':
                  isFavMaterial && isSelected,
              })}
            >
              <IconHeart2 fill="white" color="white" />
            </div>
            {isSelected && (
              <IconTick
                className="MaterialThumbnailUI__icon"
                height="22px"
                width="22px"
              />
            )}
            {isDisabled ? (
              <>
                <div className="absolute left-0 top-0 bg-[hsla(0,0%,100%,.4)] w-full h-full z-50" />
                <div className="absolute left-0 top-0 w-full h-full z-[51] [background:linear-gradient(to_top_left,transparent_0,transparent_calc(50%_-_.8px)_,#fff_50%,transparent_calc(50%_+_.8px)_,transparent)] rounded-lg" />
              </>
            ) : null}
            {isInFlashSale ? (
              <div className="w-4 h-4 absolute top-[7px] left-2">
                <IconLightningBoltFilled color="white" height={13} width={12} />
              </div>
            ) : null}
          </div>
        </div>
      </Placeholder>
    </RatioContainer>
  );
};

MaterialThumbnailUI.propTypes = {
  customToggleFlyOut: PropTypes.bool,
  customToggleFlyOutData: PropTypes.object,
  fullHeightThumbnail: PropTypes.bool,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFavMaterial: PropTypes.bool,
  isInFlashSale: PropTypes.bool,
  isRound: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  materialApplicableForProduct: PropTypes.bool,
  materialId: PropTypes.number,
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onHover: PropTypes.func,
  toggleFlyOut: PropTypes.func,
  variant: PropTypes.oneOf([
    'PDPCustomerPhotosReviewsModal',
    'PDPShownInUI',
    'PDPShownInUI--grey-background',
  ]),
};

export default MaterialThumbnailUI;
